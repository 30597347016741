
  import { Component, Prop, Watch } from 'vue-property-decorator'
  import { GForm } from '@/components/forms/GForm'
  import FormTitle from '@/components/forms/FormTitle.vue'
  import { ProcessTrafficTicket } from '@/entities/purchase'
  import Row from '@/components/toolkit/details/row/row.vue'
  import { fixDate, fixPrice } from '@/utils/general'
  import GFormSlot from '@/components/forms/GFormSlot.vue'
  import GRadioButton from '@/components/core/input/GRadioButton.vue'
  import GFiles from '@/components/core/files/GFiles.vue'
  import GDatePicker from '@/components/core/input/GDatePicker.vue'

@Component({
  components: { GDatePicker, GFiles, GRadioButton, GFormSlot, Row, FormTitle },
  methods: { fixPrice },
})
  export default class TrafficTicketComponent extends GForm {
  @Prop({ type: Boolean }) value: boolean | undefined
  @Prop({ type: Boolean, default: false }) supervisor: boolean | undefined
  @Prop({ type: Number }) idProcess: number
  @Prop({ type: Object, default: () => null }) status!: {
    toConfirm: number,
    unpaid: number,
    paid: number,
    pending: number
  }

  @Prop({ type: Object, default: () => null }) properties!: Record<string, any> | null
  @Prop({ type: Object, default: () => null }) processTrafficTicket!: ProcessTrafficTicket | null

  declare $refs: {
    form: HTMLFormElement
    traffic: GFormSlot
  };

  formData = {
    exist: null,
    paymentProof: [],
    date: null,
    comment: '',
  }

  paymentProofMessage = null
  radioButtonMessage = ''
  loading = false

  closeTrafficTicket () {
    this.$emit('input', false)
  }

  async send () {
    const { formData: { exist }, status, idProcess, properties, supervisor } = this

    if (exist === null) {
      this.radioButtonMessage = 'Este campo es requerido'
      return
    }

    if (supervisor) {
      if (!exist && !this.formData?.comment?.length) {
        this.$refs.traffic.validate()
        return
      }

      if (exist) {
        await this.pushData({
          model: 'ProcessTrafficTicket',
          fields: {
            id: this.processTrafficTicket?.id,
            id_process_status: status.paid,
          },
        })
        await this.changeValidFileProcess(this.formData.paymentProof[0], true)
      } else {
        await this.pushData({
          model: 'ProcessTrafficTicket',
          fields: {
            id: this.processTrafficTicket?.id,
            id_process_status: status.pending,
          },
        })

        await this.insertFileProcessInfo({
          id: this.formData.paymentProof[0].id,
          comment: this.formData.comment,
          valid: false,
          validation_type: 'manual',
        })
      }
      this.closeTrafficTicket()
      return
    }

    if (!this.$refs.traffic.validate() && exist) {
      return
    }

    if (!exist) {
      await this.pushData({
        model: 'ProcessTrafficTicket',
        fields: {
          id: this.processTrafficTicket?.id,
          id_process_status: status.unpaid,
        },
      })
      await this.checkValues()
    } else {
      await this.pushData({
        model: 'ProcessTrafficTicket',
        fields: {
          id: this.processTrafficTicket?.id,
          id_process_status: status.toConfirm,
        },
      })

      await this.pushData({
        model: 'TrafficTicket',
        fields: {
          id: this.processTrafficTicket?.trafficTicket.id,
          payment_date: fixDate(this.formData.date),
        },
      })

      await this.handleFileType(this.formData.paymentProof, { properties }, idProcess, this.processTrafficTicket?.trafficTicket.id)
    }
    this.closeTrafficTicket()
  }

  async checkValues () {
    const { formData } = this

    if (formData.date) {
      await this.pushData({
        model: 'TrafficTicket',
        fields: {
          id: this.processTrafficTicket?.trafficTicket.id,
          payment_date: null,
        },
      })
    }

    if (formData.paymentProof?.length) {
      await this.deleteFiles(formData.paymentProof)
    }
  }

  @Watch('formData.exist', { immediate: true })
  onExistChange (newValue: boolean | null) {
    if (newValue === null) return
    this.radioButtonMessage = ''
  }

  @Watch('processTrafficTicket', { immediate: true, deep: true })
  async onProcessTrafficTicketChange (processTrafficTicket: ProcessTrafficTicket | null) {
    if (processTrafficTicket) {
      if (!this.supervisor) {
        this.formData.exist = this.processTrafficTicket.exist
      }
      this.formData.date = processTrafficTicket.trafficTicket.paymentDateFormatted
      this.formData.paymentProof = await this.fetchData({
        query: { name: 'find', model: 'FileProcess' },
        filter: {
          _and: [
            { id_process_record: { _eq: this.processTrafficTicket.trafficTicket.id } },
            { parameter: { process: { id: { _eq: this.idProcess } } } },
            { parameter: { file_type: { name: { _eq: 'photo' } } } },
          ],
        },
        force: true,
      })
    }
  }
  }
